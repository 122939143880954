@use "@material/fab";

@include fab.core-styles;

.App {
  text-align: center;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.GooglePlayBadge {
  width: 300px;
}

.ChecklistTemplatesSection {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 24px;
  row-gap: 24px;
}

.ChecklistTemplateItem {
  width: fit-content;
  min-width: 240px;
  min-height: 140px;
  padding: 16px 24px 16px 24px;
  text-align: start;
}

.TemplatesLabel {
  grid-column: 1 / span 4;
  width: 100%;
  text-align: left;
  font-size: 26px;
}

.FullWidthDiv {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.Landing {
  /*background-color: #282c34;*/
  min-height: 100vh;
  /*height: 100%;*/
  justify-content: center;
  align-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
}

.EditTemplate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Input {
  margin: auto;
  width: 20%;
}

.CenterFab {
  width: auto;
  margin: auto;
}

.dashboard {
  height: 25vh;
  width: 100%;
  grid-column: 1 / span 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
}

.HeroScreenshot {
  width: 350px;
}

.Screenshot {
  width: 300px;
}

.logo {
  width: 48px;
}

.main-container * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, sans-serif;
}

.main-container body {
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
  position: relative;
  background: #fff;
}

.container {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 738px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .container {
    width: 962px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.main-container {
  color: #222;
  text-align: left !important;
  padding: 2em;
  background: #fff;
  min-height: 400px;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25)
}

.main-container * {
  text-align: left !important
}

.main-container :not(a) {
  color: #222
}

.main-container #content p {
  margin-top: 1rem;
  margin-bottom: 1rem
}

a.nohover:hover {
  text-decoration: none;
}

.container h1 {
  font-size: 3.2em;
  margin-bottom: 0;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.container h2 {
  margin-top: 0.2em;
  margin-bottom: 0.3em;
  font-size: 1.8em;
}

.container h3 {
  font-weight: normal;
  font-size: 16px;
}